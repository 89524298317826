@tailwind base;
@tailwind components;
@tailwind utilities;

video.nocontrols::-webkit-media-controls {
  display: none;
}

video::-webkit-media-controls-fullscreen-button {
  display: none;
}

video::-webkit-media-controls-volume-slider {
  display: none;
}

video::-webkit-media-controls-mute-button {
  display: none;
}
